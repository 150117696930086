<template>
  <div class="new-con-box">
    <!-- 导航栏-->
    <Nav nav="news"></Nav>
    <!--    菜单-->
    <sideMenu></sideMenu>
    <!--  内容-->
    <div class="container-box">
      <!--    banner-->
      <div class="banner-box">
        <img src="https://file.xssbdc.com/static/website/banner5.png" alt="">
        <div>
          <span class="banner-text-title">新闻资讯</span>
          <span class="banner-text-title-en">NEWS</span>
        </div>

      </div>
      <!--    资讯列表-->
      <div class="news-list">
        <!--    <div class="container">-->
        <div class="row new-item" v-for="(item,index) in list" :key="index" @click="jumpDetail(item.id)">
          <img :src="item.coverImg" class="col-md-4 col-sm-12" alt="">
          <div class="new-con col-md-8 col-sm-12">
            <div class="new-tit">{{ item.title }}</div>
            <div class="new-time">{{ item.createTime }}</div>
            <div class="new-text" v-html="item.content"></div>
          </div>
          <!--      </div>-->
        </div>
      </div>
      <!--    分页-->
      <paging :total="total" @currentChange="currentChange"></paging>
    </div>
    <!--      脚标-->
    <tfooter></tfooter>
  </div>
</template>

<script>
import Nav from '../components/nav';
import sideMenu from '../components/side-menu';
import tfooter from "@/components/footer";
import paging from "@/components/paging";
import url from '../js/config';

export default {
  name: "news",
  components: {Nav, sideMenu, tfooter, paging},
  data() {
    return {
      pageNo: 1,
      list: [],
      total: 0,
    }
  },
  mounted() {
    this.getNewList();
  },
  methods: {
    //去详情页
    jumpDetail(id) {
      this.$router.push(`/new-details?id=${id}`)
    },
    //当前页码发生改变
    currentChange(e) {
      this.pageNo = e;
      this.getNewList();
      console.log(this.docWz)
      window.$('.news-list')[0].scrollIntoView(true);
    },
    //获取列表
    getNewList() {
      this.$axios.get(url + '/business/websiteNews/newsForUccn', {
        params: {
          pageNo: this.pageNo,
          classify: 0
        }
      }).then((res) => {
        this.total = res.data.data.total;
        this.list = res.data.data.records;
      })
    },
  },
}
</script>


<style scoped lang="less">
.new-con-box {
  background-color: #fff;
}

.banner-box {
  width: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .banner-text-title {
      font-size: 76px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 66px;
    }

    .banner-text-title-en {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
    }
  }

}

/*资讯列表*/

.news-list {
  background-color: #fff;
  padding: 140px 15%;

  .row {
    margin-left: 0;
  }

  .new-item {
    width: 100%;
    background-color: #fff;
    margin-bottom: 100px;

    img {
      padding: 0;
      //width: 100%;
      height: 280px;
    }

    .new-con {
      display: flex;
      //align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 280px;
      //padding-top: 30px;
      padding-left: 30px;

      .new-tit {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-weight: bold;
        color: #333333;
        margin-bottom: 35px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }

      .new-time {
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-bottom: 40px;
      }

      .new-text {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }

  .new-item:hover {
    background-color: #f8f8f8;
  }
}
</style>