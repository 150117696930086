<template>
  <div class="pag-con">
    <el-pagination
        layout="prev, pager, next"
        :total="total"
        background  :pager-count="5" @current-change="currentChange">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "paging",
  props:{
    total:Number,
  },
  methods:{
    currentChange(e){
      console.log(e)
      this.$emit('currentChange', e);
    },
  }
}
</script>

<style scoped lang="less">
.pag-con {
  width: 100%;
  background-color: #fff;
  margin-bottom: 130px;
}
</style>
<style>
.el-pagination{
 text-align: center;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #000;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #000;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #fff;
}
</style>